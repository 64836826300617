import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import "./i18n";
import { MegaApp } from "./MegaApp";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <MegaApp />
    {/* </React.StrictMode> */}
  </Provider>
);
// ReactDOM.render(<App/>, document.getElementById('root'));
