import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "./Constants/Constant";

const langType = localStorage.getItem("lang_type");

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    lng: !langType ? LANGUAGES.JP : langType,
    fallbackLng: LANGUAGES.JP, 
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_BASE_URL}api/bookingportal/locale/{{lng}}`,
    },
    supportedLngs: [LANGUAGES.EN, LANGUAGES.JP], 
  });

export default i18n;
