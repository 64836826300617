export const API_PATH = {
  CONFIG: "api/bookingportal",
  CLINIC_DETAILS: "api/bookingportal/get_clinic", // GET PREFERRED FAICILITY
  CLINIC_SERVICE: "api/bookingportal/get_clinic_service", // GET PREFERRED SERVICES
  DOCTORS_DETAILS: "api/bookingportal/service", // GET DOCTORS OR TIME SLOTS
  PROVIDER_DETAILS: "api/bookingportal/provider", 
  SLOTS_DETAILS: "api/bookingportal/slots",
  CHECK_DOCTORS: "api/bookingportal/check_doctor",
  // SPECIES_LIST: "api/bookingportal/pet/species",
  // BREED_LIST: "api/bookingportal/pet/breeds",
  CREATE_APPOINTMENT: "api/bookingportal/create",
  DASHBOARD_DATA: "api/dashboard",
  RESCHEDULE_APPOINTMENT:"api/bookingportal/update",
CANCEL_APPOINTMENT:"api/bookingportal/cancel",
  // NEW LOGIN FLOW
  VERIFY: "api/auth/verify",
  SEND_OTP: "api/auth/send-otp",
  VERIFY_OTP: "api/auth/verify-otp",
  VERIFY_EMAIL: "api/auth/verify-email",
  VERIFY_REGISTRATION_TOKEN: "api/auth/verify-registration-token",
  USER_REGISTER: "api/auth/create-profile",
  // CREATE PET
  CREATE_PET: "api/create-pet",
  GET_PETS: "/api/pets",
  GET_SPECIES: "api/bookingportal/pet/species",
  GET_BREEDS: "api/bookingportal/pet/breeds",
  LOGIN:"api/auth/login",
  RESET_PASSWORD: "api/auth/reset-password",
  VERIFY_RESET_PASSWORD_TOKEN: "api/auth/verify-reset-password-token",
  CREATE_PASSWORD: "api/auth/create-password"
};
